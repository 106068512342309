import { HowItWorksProps } from "~/dataModels/HowItWorks/HowItWorks.models";

export const mapToHowItWorksItems = (data: any): HowItWorksProps => {
    return {
        title: data.title.value,
        subtitle: data.subtitle.value,
        description: data.description.value,
        learn_more_button_text: data.learnMoreButtonText.value,
        learn_more_button_url: data.learnMoreButtonUrl.value,
        steps: data.steps.linkedItems.map((item: any) => {           
            return {
                title: item.elements.title.value,
                content: item.elements.content.value,
            };
        }),
        step_icons: data.stepIcons.value
    };
};


