import { HowItWorksProps } from "@dataModels/HowItWorks/HowItWorks.models";
import React from "react";
import { ButtonWrapper, Description, HowItWorksSection, LeftColumnWrapper, NumberColumn, NumberInnerStyle, NumberOuterStyle, StepCotent, StepTitle, Subtitle, TextWrapper, Title, VerticalDivider } from "./CompHowItWorks.styles";
import { Grid } from "semantic-ui-react";
import KpButton from "@components/elements/KpButton/KpButton";
import theme from "@styles/theme";
import darkTheme from "~/styles/dark-theme";


class CompHowItWorks extends React.Component<HowItWorksProps,any> {
    constructor(props:HowItWorksProps) {
        super(props);
    }

    render() {
        const step1 = this.props.step_icons[0].url
        const step2 = this.props.step_icons[1].url
        const step3 = this.props.step_icons[2].url
        return(
            <HowItWorksSection darkTheme={darkTheme}>
                <Grid stackable columns={2}>
                    <LeftColumnWrapper>
                        <Title darkTheme={darkTheme}>{this.props.title}</Title>            
                        <Subtitle darkTheme={darkTheme}>{this.props.subtitle}</Subtitle>
                        <Description>{this.props.description}</Description>
                        <div className="hidden md:block">
                            <ButtonWrapper>
                                <KpButton id='hiw_learn_more' buttonType='primary' link={this.props.learn_more_button_url} color={theme.brand.colors.blue} darkTheme={true}>{this.props.learn_more_button_text}</KpButton>
                            </ButtonWrapper> 
                        </div>
                    </LeftColumnWrapper>
                    <Grid.Column>
                        <Grid columns={2}>
                            <NumberColumn>
                                {
                                    this.props.step_icons && this.props.step_icons.length <= 3 &&
                                    <img src={step1} className="w-[51px] h-[51px] max-w-none"/>
                                }                                
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <VerticalDivider></VerticalDivider>
                                    </Grid.Column>
                                </Grid.Row>
                                {
                                    this.props.step_icons && this.props.step_icons.length <= 3 &&
                                    <img src={step2} className="w-[51px] h-[51px] max-w-none"/>
                                }  
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <VerticalDivider></VerticalDivider>
                                    </Grid.Column>
                                </Grid.Row>
                                {
                                    this.props.step_icons && this.props.step_icons.length <= 3 &&
                                    <img src={step3} className="w-[51px] h-[51px] max-w-none"/>
                                }  
                            </NumberColumn>
                            <Grid.Column width={12}>
                                <div>{this.props.steps.map((item, index)=>{
                                        return (
                                            <TextWrapper key={index}>
                                                <StepTitle>{item.title}</StepTitle>                                                                           
                                                <StepCotent darkTheme={darkTheme} dangerouslySetInnerHTML={{ __html: item.content}}></StepCotent>
                                            </TextWrapper>
                                        )
                                    })}</div>
                            </Grid.Column>
                        
                        </Grid>
                    </Grid.Column>
                </Grid> 
                
                <ButtonWrapper className="block md:hidden">
                    <KpButton id='hiw_learn_more' buttonType='primary' link={this.props.learn_more_button_url} color={theme.brand.colors.blue}>{this.props.learn_more_button_text}</KpButton>
                </ButtonWrapper> 
                                 
            </HowItWorksSection>
            
        )
    }
}

export default CompHowItWorks