import styled from "@emotion/styled";
import mq from "@styles/mq";
import exp from "constants";
import facepaint from "facepaint";
import React from "react";
import { Grid } from "semantic-ui-react";


const breakpoints = facepaint([
    "@media(min-width: 768px)",
    "@media(min-width: 1024px)",
    "@media(min-width: 1170px)",
  ])

export const HowItWorksSection = styled(({darkTheme, ...rest})=><div {...rest} />)`
    color: ${p=>p.darkTheme ? "white" : "black"};
    ${mq({
        marginLeft: ['4px','auto','auto'],
        marginRight:['4px','auto','auto']
        })}
    max-width: 984px;
`

export const Title = styled(({darkTheme, ...rest})=><div {...rest} />)`
    ${mq({
        fontSize: ['14px','16px','16px'],
    })}
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.24px;
    color: ${p=>p.darkTheme ? "#eee" : "#707070"};
    margin-bottom: 16px;
    
`
export const Subtitle = styled(({darkTheme, ...rest})=><div {...rest} />)`
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color:${p=>p.darkTheme ? "#fff" : "#000"};
    margin-bottom: 10px;
`
export const Description = styled.div`
    color: #eee;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 28px;
`
export const NumberOuterStyle = styled.div`&&&&{
    background: #3dae2b;
    border-radius: 50%;
    float: left;
    margin-right: 6px;
    ${breakpoints({
      width: ["51px", "51px", "51px", "51px"],
      height: ["51px", "51px", "51px", "51px"],
    })}
`

export const NumberInnerStyle = styled.div`
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 13px 16px;
`

export const VerticalDivider = styled.div`
    line-height: 48px;
    margin: 16px 0px;
    width: 65%;
    float: left;
    border-right: #707070 4px solid;
     ${mq({
        height: ['48px','35px','28px'],
        })}
`
export const TextWrapper = styled.div`
${mq({
    height: ['135px','126px','120px'],
})}
`
export const StepTitle = styled.div`
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const StepCotent = styled(({darkTheme, ...rest})=><div {...rest} />)`
    > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: "#eee";
    margin: 4px 0px 50px 0px;
    }
`

export const ButtonWrapper = styled.div`
    margin-bottom: 12px;
    ${mq({
        marginTop: ['0px','64px','64px'],
        marginLeft:['','-10px','-10px']
    })}
`

export const NumberColumn = styled(Grid.Column)`
    width:70px !important;
`
export const LeftColumnWrapper = styled(Grid.Column)`
        margin: auto;
`
export const RightColumnWrapper = styled(Grid.Column)`
    margin-bottom:14px;   
`